export default [
  {
    path: '/',
    redirect: '/confirmOrder'
  },
  // 确认订单
  {
    path: 'confirmOrder',
    name: 'confirmOrder',
    component: () => import('@/views/order/confirmOrder.vue')
  },
  // 确认订单-康语定制订单
  {
    path: 'confirmBuy',
    name: 'confirmBuy',
    component: () => import('@/views/order/confirmBuy.vue')
  },
  // 订单列表-康语用户订单
  {
    path: 'customerOrder',
    name: 'customerOrder',
    component: () => import('@/views/order/customerOrder.vue')
  },
]
