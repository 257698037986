/*
 *	全局正则表达式
 * 
 * 	@return {Object} 返回表达式及不正确的提示语
 */
export default {
  // 验证姓名
  name: {
    reg: /^[a-zA-Z\u4E00-\u9FA5\uf900-\ufa2d·s]{2,20}$/,
    msg: '姓名格式不正确'
  },
  // 验证手机号
  phone: {
    reg: /^1[3-9]\d{9}$/,
    msg: '手机号格式不正确'
  },
  // 验证密码
  password: {
    // reg: /^.{8,24}$/,
    reg: /^(?![^a-zA-Z]+$)(?!D+$).{8,16}$/,
    msg: '密码为8~16位的数字、字母组成'
  },
  // 短信验证码
  dxcode: {
    reg: /^\d{6}$/,
    msg: '验证码为6位纯数字'
  },
  // 身份证
  idCard: {
    reg: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
    msg: '身份证格式不正确'
  },
  // 港澳台通行证
  idCard1: {
    reg: /^8[123]0000(?:19|20)\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])\d{3}[\dX]$/,
    msg: '身份证格式不正确'
  },
  // 港澳台通行证、身份证
  idCardAll: {
    reg: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$|^8[123]0000(?:19|20)\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])\d{3}[\dX]$/,
    msg: '身份证格式不正确'
  },
  // 护照
  passport: {
    reg: /^1[45][0-9]{7}$|(^[P|p|S|s]\d{7}$)|(^[S|s|G|g|E|e]\d{8}$)|(^[Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8}$)|(^[H|h|M|m]\d{8,10}$)/,
    msg: '护照格式不正确'
  },
  // 验证邮箱(选填)
  email: {
    reg: /^([a-zA-Z]|[0-9])(\w|\-)+@[a-zA-Z0-9]+\.([a-zA-Z]{2,4})|(^$)$/,
    msg: '邮箱格式不正确'
  },
  // 验证纯数字
  number: {
    reg: /[0-9]{1,}$/,
    msg: '请输入数字'
  },
  // 发票抬头
  invoiceTitle: {
    reg: /^[0-9a-zA-Z\(\)\（\）\u4e00-\u9fa5]{0,50}$/,
    msg: '发票抬头格式不正确'
  },
  // 纳税号
  TaxNumber:{
    reg: /[0-9A-HJ-NPQRTUWXY]{2}\d{6}[0-9A-HJ-NPQRTUWXY]{10}/,
    msg: '税号格式不正确'
  },
  // 快递单号
  postNum: {
    reg: /^([0-9]{8,9}|[0-9]{12,13})$/,
    msg: '快递单号格式不正确'
  },
  // 图片限制
  videoReg: {
    reg: /\.(flv|mov|mp4|avi|wmv|mp4v)$/,
    msg: '请上传正确格式的视频文件'
  },
  // 视频限制
  imgReg: {
    reg: /\.(jpg|png|gif|jpeg|bmp)(\?.*)?$/,
    msg: '请上传正确格式的图片文件'
  },
}
