import Vue from 'vue'
import Vuex from 'vuex'
// 登录的所有状态
import userInfo from './userInfo.js'
// 确认订单数据
import confirmOrder from './confirmOrder.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    closeSectionIdArry: []
  },
  mutations: {
    updatecloseSectionIdArry(state, props){
      state['closeSectionIdArry'] = props;
    },
  },
  actions: {
  },
  modules: {
    userInfo,
    confirmOrder
  }
})
