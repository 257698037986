<template>
  <div>
    <!-- 全局提示框 -->
    <a-modal
      :maskClosable='maskClosable ? false : true'
      :visible="isPublicTipsShow"
      width="480px"
      :closable="false"
      @cancel="handleCancel"
    >
      <div class="modal-content">
        <p class="tips">
          <img
            src="@/assets/image/personalCenter/2022050913.png"
            alt=""
          />提示信息
        </p>
        <p class="content" v-html="tipsText"></p>
        <div class="btn-foot">
          <p v-show="isCancelBtnShow != false" class="cancel" @click="handleCancel">{{CancelBtnText ? CancelBtnText:'取消'}}</p>
          <p v-show="isConfirmBtnShow != false" class="all-btn-small" @click="onConfirm">{{ConfirmBtnText ? ConfirmBtnText : '确认'}}<a-icon v-if="isConfirmLoading" type="loading" /></p>
        </div>
      </div>
      <template #footer></template>
    </a-modal>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: [
    'isPublicTipsShow',  // 控制弹窗显示/隐藏  必填
    'tipsText',  // 提示文案   必填
    'isCancelBtnShow',  // 控制取消按钮 显示/隐藏  /**  默认文案 取消  **/
    'CancelBtnText',  // 取消按钮文字
    'isConfirmBtnShow',  // 控制确认按钮 显示/隐藏  /**  默认文案 确认  **/
    'ConfirmBtnText',  // 确认按钮文字
    'isConfirmLoading', //确认按钮loading
    'maskClosable',  // 是否允许点击遮罩进行
  ],
  // 数据对象
  data () {
    return {}
  },
  // 事件处理器
  methods: {
    handleCancel(e) {
      this.$parent.isPublicTipsShow = false;
      this.$parent.confirmPopup = false;
    },
    onConfirm(){
      this.$emit('setEvent')
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.modal-content {
  .tips {
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    color: @theme;
    img {
      margin-right: 7px;
    }
  }
  .input {
    height: 40px;
    margin-top: 20px;
  }
  .content {
    font-size: 16px;
    font-weight: 400;
    font-family: PingFang SC-Regular, PingFang SC;
    color: #333333;
    line-height: 23px;
    margin:  20px;
  }
  .btn-foot {
    display: flex;
    justify-content: center;
    width: 340px;
    margin: 0 auto;
    margin-top: 30px;
    .cancel {
      text-align: center;
      line-height: 36px;
      border-radius: 18px;
      width: 113px;
      height: 36px;
      color: @theme;
      border: 1px solid @theme;
      cursor: pointer;
    }
    .all-btn-small {
      border-radius: 18px;
      width: 113px;
      height: 36px;
      line-height: 36px;
      .anticon {
        margin-left: 10px;
      }
    }
    p{
      margin: 0 30px;
    }
  }
}
/deep/ .ant-modal-footer {
  display: none;
}
/deep/ .ant-modal {
  top: 260px;
}
</style>
