/*
	登录状态判断黑名单
		黑名单目的是为了需要登录才能访问的，
		如果当前页面不需要登录即可访问，
		请不要加入黑名单
		
		页面路径在控制台有打印，复制到下面的数组即可
*/
export default [
  '/personalCenter/MyCourse',   // 我的课程
  '/personalCenter/identityAuthentication',   // 身份认证
	'/personalCenter/MyOrder',   // 我的订单
	'/knowledgeMall/orderDetail', // 订单详情
	'/personalCenter/myCoupon',   // 我的优惠券
	'/personalCenter/cellphoneNumber',   // 修改手机号
	'/personalCenter/Password',   // 修改密码
  '/personalCenter/addressmanage',   // 收货地址
  '/personalCenter/inVBRK',   // 发票抬头
	'/knowledgeMall/examSystem',   // 模拟考试
  // '/study/study',   // 学习中心
  '/curriculum/course',   // 按顺序播放课程
  '/curriculum/courseRandom',   // 不按顺序播放课程
  '/homework/homeworkList',   // 作业列表
  '/homework/homeworkSubmit',   // 作业添加
  '/homework/homeworkDetail',   // 作业详情
	'/ebookDetail', // 电子书
]