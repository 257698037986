const COS = require('cos-js-sdk-v5');
import ajax from './ajax.js'

// 获取临时权限
function getToKen(name){
  return ajax({
    // url: '/hxclass-management/speak/security/token',
    url: '/hxclass-pc/cos/get-credential',
    params: {
      // file_name: name
      fileName: name
    }
  }).then(res=>{
    if(res.code == 200 && res.success){
      return Promise.resolve(res)
    }
  })
}

/*
  文件上传
  调用方式：
    upload({
      file: fileObject,
      progress: (e)=>{},
      success: (e)=>{}
    })
*/
export default function upload({
  file,
  progress,
  success
}){
  // 走后端上传文件
  let formData = new FormData();
  formData.append('multipartFile',file);
  ajax({
    url: '/hxclass-pc/cos/upload',
    method: 'POST',
    params: formData
  }).then(res=>{
    if(res.code == 200 && res.success) {
      success(res.data.url)
    }else{
    
    }
  })

  // 云上传
  // let config = new Object()
  // getToKen(file.name).then(res=>{
  //   config = res.data
  //   const cos = new COS({
  //     getAuthorization: (options, callback)=>{
  //       callback({
  //         TmpSecretId: config.credentials.tmpSecretId,
  //         TmpSecretKey: config.credentials.tmpSecretKey,
  //         SecurityToken: config.credentials.sessionToken,
  //         StartTime: config.startTime,
  //         ExpiredTime: config.expiredTime,
  //       });
  //     }
  //   });
  //   cos.uploadFile({
  //     Bucket: config.bucket,
  //     Region: config.region,
  //     // Key: config.keyPrefix + '/' + file.name,
  //     Key: config.key,
  //     StorageClass: 'STANDARD',
  //     SliceSize: 1024 * 1024 * 5,
  //     Body: file, // 上传文件对象
  //     onProgress: function(progressData) {
  //       // 上传进度
  //       progress(progressData)
  //     }
  //   }, function(err, data) {
  //     // 上传结果
  //     if(data.statusCode == 200){
  //       // success('https://'+data.Location)
  //       success(config.host + config.key)
  //     }else{
  //       // console.log(err || data)
  //     }
  //   })
  // })
}