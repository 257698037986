import Vue from 'vue'
import VueRouter from 'vue-router'
import login from './login'
import personalCenter from './personalCenter'
import knowledgeMall from './knowledgeMall';
import study from './study';
import Query from './Query';
import curriculum from './curriculum'
import home from './home'
import examination from './examination'
import stop from './stop'
import order from './order'
import pay from './pay'
import invoice from './invoice'
import homework from './homework'
import activity from './activity'
import $store from './../store'
import WhiteList from '@/assets/js/WhiteList.js'  // 路由黑名单

Vue.use(VueRouter)

const routes = [
  // {
  //   path: '/',
  //   name: 'index',
  //   component: () => import('@/views/index/index.vue')
  // },
  {
    path: '/',
    children: home,
    component: () => import('@/views/routerView.vue')
  },
  // 课程
  {
    path: '/curriculum',
    children: curriculum,
    component: () => import('@/views/routerView.vue')
  },
  // 考试
  {
    path: '/examination',
    children: examination,
    component: () => import('@/views/routerView.vue')
  },
  // 书课专区
  {
    path: '/knowledgeMall',
    children: knowledgeMall,
    component: () => import('@/views/routerView.vue')
  },
  // 学习
  {
    path: '/study',
    children: study,
    component: () => import('@/views/routerView.vue')
  },
  // 认证查询
  {
    path: '/Query',
    children: Query,
    component: () => import('@/views/routerView.vue')
  },
  // 停服
  {
    path: '/stop',
    children: stop,
    component: () => import('@/views/routerView.vue')
  },
  // 登录注册页面分组
  {
    path: '/login',
    children: login,
    component: () => import('@/views/routerView.vue')
  },
  // 个人中心
  {
    path: '/personalCenter',
    children: personalCenter,
    component: () => import('@/views/routerView.vue')
  },
  // 订单
  {
    path: '/order',
    children: order,
    component: () => import('@/views/routerView.vue')
  },
  // 支付
  {
    path: '/pay',
    children: pay,
    component: () => import('@/views/routerView.vue')
  },
  // 发票
  {
    path: '/invoice',
    children: invoice,
    component: () => import('@/views/routerView.vue')
  },
  // 作业
  {
    path: '/homework',
    children: homework,
    component: () => import('@/views/routerView.vue')
  },
  // 活动
  {
    path: '/activity',
    children: activity,
    component: () => import('@/views/routerView.vue')
  },
  // 购物车
  {
    path: '/shopBar',
    children: [
      {
        path: '/',
        name: 'shopBar',
        component: () => import('@/views/shopBar/index.vue')
      }
    ],
    component: () => import('@/views/routerView.vue')
  },
  // 错误页面error,所有找不到的 * 匹配错误页面都放在最下面
  {
    path: '*',
    component: () => import('@/views/error.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

// 前置路由守卫
// router.beforeEach((to, from, next) => {
//   // 如果页面路径不是404，则进入判断
//   if(!to.params.pathMatch){
//     const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {}
//     const isLogin = $store.state.userInfo.isLogin
//     // 如果去往的页面在黑名单,则需要判断是否登录
//     if(WhiteList.indexOf(to.path) != -1){
//       if(isLogin || userInfo.isLogin){
//         next()
//       } else {
//         next({
//           path: '/login/loginIndex?type=2'
//         })
//       }
//     } else {
//       next()
//     }
//   }else{
//     next()
//   }
// })

// 后置路由守卫
router.afterEach((to, from) => {
  // 如果页面路径不是404，则进入判断
  if(!to.params.pathMatch){
    // 当前访问环境是微信浏览器，域名重定向到h5
    const isWeixinBrowser = /micromessenger/i.test(navigator.userAgent)
    if (isWeixinBrowser) { 
      window.location.href = 'https://wx.hxclass.cn/'
      return
    }

    // 当前访问地址是测试地址。需要二次认证
    const isCertified = localStorage.getItem("isCertified") || false
    if((window.location.origin == 'https://jh43wf8954wfw56.hxclass.cn' || window.location.origin == 'https://gray.hxclass.cn') && !isCertified){
      router.push({
        path: '/pswLogin'
      })
      return
    }

    const userInfo = JSON.parse(localStorage.getItem("userInfo")) || {}
    const isLogin = $store.state.userInfo.isLogin
    // 如果去往的页面在黑名单,则需要判断是否登录
    if(WhiteList.indexOf(to.path) != -1){
      if(isLogin || userInfo.isLogin){
        // next()
      } else {
        router.push({
          path: '/login/loginIndex?type=2'
        })
      }
    } else {
      // next()
    }
  }else{
    // next()
  }
})

export default router
