export default[
  {
    path: '/',
    redirect: 'applyInvoice'
  },
  // 申请发票
  {
    path: 'applyInvoice',
    component: () => import('@/views/invoice/applyInvoice.vue')
  },
  // 换开发票
  {
    path: 'replaceVoice',
    component: () => import('@/views/invoice/replaceVoice.vue')
  },
  // 发票详情
  {
    path: 'InvoiceDetail',
    component: () => import('@/views/invoice/InvoiceDetail.vue')
  },
]