import ajax from './ajax.js'

let paramsObj = null;
let equipment = null;
// 获取唯一标识
function getEquipment() {
  // 创建一个 RTCPeerConnection 对象
  const pc = new RTCPeerConnection();
  // 创建一个虚拟的数据通道
  pc.createDataChannel("");
  // 监听 ICE 候选对象的生成
  pc.onicecandidate = function (event) {
    if (event.candidate) {
      equipment = event.candidate.address;
      if(equipment){
        userTrack(paramsObj)
      }
    } else {
      // console.error("未获取到当前设备信息");
    }
  };
  // 创建一个虚拟的 offer
  pc.createOffer()
    .then(offer => pc.setLocalDescription(offer))
    .catch(error => console.error("Error:", error));
}

// 记录用户轨迹
export function userTrack(params){
  if(!equipment) {
    paramsObj = params;
    return getEquipment()
  }
  return ajax({
    url: '/hxclass-pc/user/userTrack/save',
    method: 'POST',
    params: {
      equipment, //	设备唯一标识
      ...params
    }
  }).then(res=>{
    if(res.code == 200 && res.success){
      
    }
  })
}