export default [
  {
    path: '/',
    redirect: 'study'
  },
  {
    path: 'study',
    component: () => import('@/views/study/index.vue')
  },
  {  // 我的课程
    path: '/study/MyCourse',
    component: () => import('@/views/personalCenter/MyCourse.vue')
  },
  {
    path: 'mocklist',
    component: () => import('@/views/study/mocklist.vue')
  },
  // 考试报名/安排
  {
    path: 'examSignup',
    component: () => import('@/views/study/examSignup.vue')
  },
  // 考试报名信息填写
  {
    path: 'registrationMessage',
    component: () => import('@/views/study/registrationMessage.vue')
  },
  // 考试通过信息确认
  {
    path: 'signUpConfirmOk',
    component: () => import('@/views/study/signUpConfirmOk.vue')
  },
  // 考试通过信息确认详情
  {
    path: 'signUpConfirmDetail',
    component: () => import('@/views/study/signUpConfirmDetail.vue')
  },
  // 考试通过信息确认-机构
  {
    path: 'signUpConfirmGroup',
    component: () => import('@/views/study/signUpConfirmGroup.vue')
  },
]