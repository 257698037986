import $store from './../store'
export default [
  {
    path: '/',
    redirect: 'loginIndex'
  },
  // 登录注册
  {
    path: 'loginIndex',
    name: 'loginIndex',
    component: () => import('@/views/login/index.vue'),
    beforeEnter: (to,from,next) => {
      if(from.path != '/privacyPolicy' && from.path != '/login/findPassword') {
        $store.state.userInfo.tolink = from.fullPath
      }
      next()
    }
  },
  // 找回密码
  {
    path: 'findPassword',
    name: 'findPassword',
    component: () => import('@/views/login/findPassword.vue')
  },
  // 绑定手机号
  {
    path: 'bindPhone',
    name: 'bindPhone',
    component: () => import('@/views/login/bindPhone.vue')
  },
  // 客户订单查询登录页
  {
    path: 'customerLogin',
    name: 'customerLogin',
    component: () => import('@/views/login/customerLogin.vue')
  },
]
