export default[
  // 作业列表
  {
    path: 'homeworkList',
    component: () => import('@/views/homework/homeworkList.vue')
  },
  // 作业提交
  {
    path: 'homeworkSubmit',
    component: () => import('@/views/homework/homeworkSubmit.vue')
  },
  // 作业详情/反馈记录
  {
    path: 'homeworkDetail',
    component: () => import('@/views/homework/homeworkDetail.vue')
  },
]