<template>
  <a-modal
      :visible="visible"
      :confirm-loading="confirmLoading"
      @cancel="handleCancel"
    >
      <template #title>
        <a-icon class="icon" type="info-circle" />提示信息
      </template>
      <p class="title">{{fontText}}</p>
      <!-- <div class="imgs">
        <div class="imgItem">
          <img src="@/assets/image/nav/20220619-211727.png" alt="">
        </div>
      </div> -->
      <template #footer>
        <p class="all-btn-small" @click="handleCancel">我知道了</p>
      </template>
    </a-modal>
</template>

<script>
export default {
  props:['visible','fontText'],
  data(){
    return {
      ModalText: 'Content of the modal',
      confirmLoading: false,
    }
  },
  methods: {
    handleCancel(e) {
      this.$parent.visible = false;
    },
  },
}
</script>

<style scoped lang="less">
  /deep/.ant-modal-header{
    border: 0 !important;
    padding: 33px 32px 8px;
  }
  /deep/.ant-modal-title{
    font-size: 20px !important;
    font-family: PingFangMedium;
    font-weight: bold;
    color: #15B7DD !important;
    line-height: 23px !important;
    .icon{
      margin-right: 8px;
    }
  }

  /deep/.ant-modal-body{
    padding: 24px 65px;
    .title{
      font-size: 16px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #000000;
      line-height: 24px;
    }
    .imgs{
      margin-top: 20px;
      display: flex;
      justify-content: space-around;
      .imgItem{
        text-align: center;
        img{
          width: 120px;
          height: 120px;
        }
        p{
          margin-top: 8px;
          font-size: 16px;
          font-family: PingFangMedium;
          color: #000000;
          line-height: 24px;
        }
      }
    }
  }
  /deep/.ant-modal-footer{
    border: 0;
    padding-top: 16px;
    padding-bottom: 40px;
    .all-btn-small{
      margin: 0 auto;
    }
  }
</style>