<template>
  <div>
    <!-- 登录 -->
    <login 
      v-if="$store.state.userInfo.showLoginPop" 
      :type='type' 
      :state='state' 
      @changeLoginPop='changeLoginPop' 
      @changeType="changeType"></login>

    <!-- 找回密码 -->
    <findPassword 
      v-if="$store.state.userInfo.showFindPop" 
      :state='state'
      @closeFindPop="closeFindPop()"></findPassword>

    <!-- 绑定手机号 -->
    <bindPhone 
      v-if="$store.state.userInfo.showBindPop"
      :state='state'
      @closeBindPop="closeBindPop()"></bindPhone>
  </div>
</template>

<script>
import login from "@/components/login/login.vue";
import findPassword from "@/components/login/findPassword.vue";
import bindPhone from "@/components/login/bindPhone.vue";
export default {
  // 可用组件的哈希表
  components: {
    login, findPassword, bindPhone
  },
  // 接收传值
  props: {

  },
  // 数据对象
  data () {
    return {
      // showLoginPop: false, //登录注册弹框   
      // showFindPop: false, //找回密码弹框 
      // showBindPop: false, //绑定手机号弹框

      state: 2, //1页面 2弹框
      type: 2, //1注册 2登录
      loginType: '1', //登录方式 1密码 2短信
    }
  },
  // 事件处理器
  methods: {
    // 注册登录切换
    changeType(e) {
      this.type = e
    },
    // 关闭登录弹框
    changeLoginPop(type) {
      // console.log(2222)
      // 关闭登录弹框
      if (type == 1) {
        let userInfo = this.$store.state.userInfo
        userInfo.showLoginPop = false
        this.$store.commit("updateUserInfo", userInfo);
      }
      // 打开忘记密码
      else if (type == 2) {
        let userInfo = this.$store.state.userInfo
        userInfo.showLoginPop = false
        userInfo.showFindPop = true
        this.$store.commit("updateUserInfo", userInfo);
      }
      // 打开绑定手机号
      else if (type == 3) {
        // console.log(3333)
        let userInfo = this.$store.state.userInfo
        userInfo.showLoginPop = false
        userInfo.showBindPop = true
        this.$store.commit("updateUserInfo", userInfo);
      }
    },
    // 关闭找回密码弹框
    closeFindPop() {
      let userInfo = this.$store.state.userInfo
      userInfo.showFindPop = false
      this.$store.commit("updateUserInfo", userInfo);
    },
    // 关闭绑定手机号弹框
    closeBindPop() {
      let userInfo = this.$store.state.userInfo
      userInfo.showBindPop = false
      this.$store.commit("updateUserInfo", userInfo);
    }
  },
  // 生命周期-实例创建完成后调用
  created () { 
    // // console.log($store.state.userInfo.showLoginPop)
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped></style>
