export default[
  {
    path: '/',
    redirect: 'payMethod'
  },
  // 支付方式
  {
    path: 'payMethod',
    component: () => import('@/views/pay/payMethod.vue')
  },
  // 微信支付
  {
    path: 'wxPay',
    component: () => import('@/views/pay/wxPay.vue')
  },
  // 对公转账
  {
    path: 'offlinePay',
    component: () => import('@/views/pay/offlinePay.vue')
  },
  // 支付成功
  {
    path: 'payOk',
    component: () => import('@/views/pay/payOk.vue')
  },
]