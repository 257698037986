import $ajax from './ajax.js'
// 公共方法
// 获取浏览器类型
// 获取当前登录的浏览器
export function getLogoinBrowser(){
  var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
  // console.log("loginuserAgent:", userAgent)
  //判断是否Opera浏览器
  if (userAgent.indexOf("Opera") > -1) {
    return "Opera浏览器"
  };
 
  //判断是否Edge浏览器
  if (userAgent.indexOf("Edg") > -1){
    return 'Edge浏览器'
  }
 
  //判断是否Firefox浏览器
  if (userAgent.indexOf("Firefox") > -1) {
    return "firefox浏览器";
  }
 
  //判断是否Chrome浏览器
  if (userAgent.indexOf("Chrome") > -1){
    return "Chrome浏览器";
  }
 
  //判断是否Safari浏览器
  if (userAgent.indexOf("Safari") > -1) {
    return "Safari浏览器";
  }
 
  //判断是否IE浏览器
  if (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera) {
    return "IE浏览器";
  }
  if ( userAgent.indexOf("Trident") > -1){
    return "IE浏览器";
  }
}

// console.log(getBrowserType());
// 告诉后台，我登陆了
export function logininformation(e) {
  return $ajax({
    url: '/hxclass-pc/user/saveUserLoginInfo',
    method: "post",
    params: {
      loginType: 'PCC',
      userId: e.userId,
      clientOs: '01',
      userName: e.userName,
      clientName: getLogoinBrowser()
    }
  })
}

