<template>
  <div></div>
</template>
<script>
// 图片地址
const lochostUrl = ''

const qrCodeUrl = '/api/' // windows
const baseURL = `${window.location.origin}${qrCodeUrl}`;
export default
  {
    // 图片错误替换
    errorimg () {
      return (
        'this.src="' +
        require('@/assets/image/defaultLevel.png') +
        '";this.onerror=null'
      )
    },
    lochostUrl,
    qrCodeUrl,
    baseURL,
    defaultImg: require('@/assets/image/defaultLevel.png'),
    defaultUserImg: require('@/assets/image/personalCenter/2022050906.png'),
  }
</script>
