const crypto = require("crypto")
const URLSafeBase64 = require('urlsafe-base64')
// 新建对象传入密钥key
// let aes = new AESCipher(this.$Global.key)
// 加密
// let encode = aes.encode_data(this.password)
// 解密
// let decode = aes.decode_data(encode)
class AESCipher {
  // 密钥key
  constructor (key) {
    this.key = key
  }
  // 加密
  encode_data (data) {
    const datajson = data
    if (typeof datajson === 'object') {
      datajson = JSON.stringify(datajson)
    }
    const cryptkey = crypto.createHash('sha256').update(this.key, 'utf8').digest().slice(0, 16)
    // 生成随机偏移
    const iv = crypto.randomBytes(16)
    const encipher = crypto.createCipheriv('aes-128-cbc', cryptkey, iv)
    // 设置默认自动填充，填充模式PKCS7
    encipher.setAutoPadding(true)
    const encdata = Buffer.concat([encipher.update(datajson, 'utf8'), encipher.final()])
    const enc = Buffer.concat([iv, encdata])
    return URLSafeBase64.encode(enc).toString('hex')
  }
  // 解密
  decode_data (enc) {
    let encdata = URLSafeBase64.decode(enc)
    const cryptkey = crypto.createHash('sha256').update(this.key, 'utf8').digest().slice(0, 16)
    const iv = encdata.slice(0, 16)
    encdata = encdata.slice(16)
    const decipher = crypto.createDecipheriv('aes-128-cbc', cryptkey, iv)
    decipher.setAutoPadding(true)
    const datajson = Buffer.concat([decipher.update(encdata, 'utf8'), decipher.final()]).toString('utf8')
    return datajson;
  }
}
export default AESCipher
