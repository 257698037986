<template>
  <div id="app" :class="{'h5App': isH5}">
    <Nav :class="{'h5Nav': isH5}" :isIndex="isIndex" :isLogin="isLogin" v-if="navShow" />
    <NavElse :class="{'h5Nav': isH5}" :isIndex="isIndex" :isLogin="isLogin" v-else />
    <!-- 未使用推荐浏览器提示信息 -->
    <div class="error-public" v-if="isDisableExam">
      <img src="@/assets/image/error.png" alt="" />
      <p>
        您可以正常使用华夏云课堂，但是为了获得更好的体验，建议您使用我们<span
          @click="isBrowserShow = true"
          >推荐的浏览器</span
        >
      </p>
      <a-icon type="close" class="icon" @click="isDisableExam = false" />
    </div>
    <div :style="minHeight" id="contentApp">
      <ConfigProvider :locale="locale">
        <router-view />
        <!-- <router-view @setIndex="getIndex"/> -->
      </ConfigProvider>
    </div>
    <Foo :class="{'h5Foo': isH5}" />
    <!-- 登录 -->
    <loginAll></loginAll>
    <a-modal
      :maskClosable="false"
      :footer="false"
      v-model="isBrowserShow"
      title="推荐浏览器"
      align="center"
    >
      <div class="browserDataTable">
        <div class="table">
          <div class="titleText">
            <p class="name">浏览器</p>
            <p class="edition">版本</p>
            <p class="url">下载</p>
          </div>
          <div class="tableContent" v-for="item in browserData" :key="item.id">
            <p class="name">{{ item.name }}</p>
            <p class="edition">{{ item.edition }}</p>
            <a target="_blank" class="url" :href="item.url">下载</a>
          </div>
        </div>
        <!-- <a-table
          :columns="browserDataColumns"
          bordered
          :rowKey="item=>item.id"
          :data-source="browserData" 
          :pagination="false"
          >
          <template slot="download" slot-scope="item">
            <a target="_blank" :href="item">下载</a>
          </template>
        </a-table> -->
      </div>
    </a-modal>
  </div>
</template>

<script>
import {userTrack} from '@/unit/userTrack.js';
import Nav from "@/components/Nav.vue";
import NavElse from "@/components/NavElse.vue";
import Foo from "@/components/Foo.vue";
import { ConfigProvider } from "ant-design-vue";
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import loginAll from "@/components/login/loginAll.vue";
import { logininformation } from "./unit/common.js";
const browserDataColumns = [
  {
    title: "浏览器",
    dataIndex: "name",
  },
  {
    title: "版本",
    dataIndex: "edition",
  },
  {
    title: "下载",
    dataIndex: "url",
    align: "center",
    scopedSlots: { customRender: "download" },
  },
];
export default {
  // 可用组件的哈希表
  components: { ConfigProvider, Nav, NavElse, Foo, loginAll },
  // 数据对象
  data() {
    return {
      browserName: "", // 浏览器名称
      limitBrowser: ["IE"], // 受限的浏览器
      isDisableExam: false, // 是否不符合推荐浏览器
      isBrowserShow: false,
      browserData: [
        {
          id: 1,
          name: "谷歌",
          edition: "92.0.4515.107 及以上版本",
          url: "https://www.google.cn/chrome/",
        },
        {
          id: 2,
          name: "火狐",
          edition: "101.0.0 及以上版本",
          url: "http://www.firefox.com.cn/download/#product-desktop-release",
        },
        {
          id: 3,
          name: "360浏览器",
          edition: "13.0.6007.0 及以上版本",
          url: "https://browser.360.cn/se/",
        },
      ],
      browserDataColumns,
      appKey: 1,
      minHeight: {},
      locale: zhCN,
      isIndex: true, // 导航栏样式
      isLogin: false, // 是否登录
      navShow: true,
      isH5: false
    };
  },
  // 事件处理器
  methods: {
    brower() {
      /* 浏览器特征：Edge——Edge浏览器，QQBrowser-QQ浏览器，UBrowser-UC浏览器，Firefox-火狐浏览器，Trident-360浏览器，
       * 其它浏览器可能包含的特征：chrome-chrome浏览器
       *
       *
       *
       */
      var browserInf = navigator.userAgent.toLowerCase();
      if (browserInf.indexOf("edge") >= 0) {
        // this.browserName = 'Edge浏览器';
        this.isDisableExam = true;
      } else if (browserInf.indexOf("qqbrowser") >= 0) {
        // this.browserName = 'QQ浏览器';
        this.isDisableExam = true;
      } else if (browserInf.indexOf("ubrowser") >= 0) {
        // this.browserName = 'UC浏览器';
        this.isDisableExam = true;
      } else if (browserInf.indexOf("firefox") >= 0) {
        // this.browserName = '火狐浏览器';
        this.isDisableExam = false;
      } else if (browserInf.indexOf("trident") >= 0) {
        // this.browserName = '360浏览器';
        this.isDisableExam = false;
      } else if (browserInf.indexOf("version") >= 0) {
        // this.browserName = 'safari浏览器';
        this.isDisableExam = false;
      } else if (browserInf.indexOf("chrome") >= 0) {
        if (!!window.ActiveXObject || "ActiveXObject" in window) {
          this.isDisableExam = true;
          return (this.browserName = "IE");
        }
        // this.browserName = 'Chrome浏览器';
        this.isDisableExam = false;
      } else {
        // this.browserName = '未知是什么鸟浏览器';
        this.isDisableExam = true;
      }
      // console.log(this.browserName,'browserName')
      // console.log(navigator.userAgent);//=>在控制台中查看navigator.userAgent
    },
    // 检测浏览器
    FunCheckBrowser() {
      var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串
      var isOpera = userAgent.indexOf("Opera") > -1;
      if (isOpera) {
        //判断是否Opera浏览器
        return "Opera";
      }
      if (userAgent.indexOf("Firefox") > -1) {
        //判断是否Firefox浏览器
        return "FF";
      }
      if (userAgent.indexOf("Chrome") > -1) {
        //判断是否Chrome浏览器
        return "Chrome";
      }
      if (userAgent.indexOf("Safari") > -1) {
        //判断是否Safari浏览器
        return "Safari";
      }
      if (
        userAgent.indexOf("compatible") > -1 &&
        userAgent.indexOf("MSIE") > -1 &&
        !isOpera
      ) {
        //判断是否IE浏览器
        return "IE";
      }
    },

    FunminHeight() {
      const obj = {
        minHeight: document.documentElement.clientHeight - 260 + "px",
      };
      this.minHeight = obj;
    },

    // 监听路由变化
    listenRoute(path) {
      if (path == "/" || path == "/login/loginIndex") {
        // this.isIndex = true
        this.isIndex = false;
      } else {
        this.isIndex = false;
      }

      const pathList = [
        '/curriculum/coursePublic',
        '/knowledgeMall/courseBuy',
        '/login/customerLogin',
        '/order/confirmBuy',
        '/order/customerOrder',
      ]
      
      // 判断导航展示
      if ((pathList.indexOf(path) != -1) || this.$route.query.source == "Friend-KY") {
        this.navShow = false;
      } else {
        this.navShow = true;
      }


      if(path == "/curriculum/course"){
        this.isH5 = true
      } else {
        this.isH5 = false
      }
    },
    // 刷新获取用户最新信息
    getUserInfo() {
      this.$ajax({
        url: "/hxclass-pc/user/info",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.succes && res.data) {
          let obj = this.$store.state.userInfo;
          obj.photo = res.data.photo;
          obj.userName = res.data.userName;
          this.$store.commit("updateUserInfo", obj); // 更新个人信息
        }
      });
    },

    // 记录用户轨迹
    saveUserTrack() {
      let params = {
        actionDetail: 1, //	访问资源id（如对应的banner、商品和文章id，路径触发先设置首页为1）
        actionType: 2, //	触发分类：1、banner触发(轮播图、弹窗)、2 路径触发(启动访问首页)、3商品详情、4 文章详情
        os: 3, //	1、ios2、安卓 3、PC 4、H5
        userId: this.$store.state.userInfo.userId
      }
      userTrack(params)
    }
  },
  beforeCreate(){
    // 当前访问环境是微信浏览器，域名重定向到h5
    const isWeixinBrowser = /micromessenger/i.test(navigator.userAgent)
    if (isWeixinBrowser) { 
      window.location.href = 'https://wx.hxclass.cn/'
    }
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 浏览器限制
    // this.isDisableExam = this.limitBrowser.indexOf(this.FunCheckBrowser()) != -1;
    this.brower();
    // 判断导航展示
    const pathList = [
      '/curriculum/coursePublic',
      '/knowledgeMall/courseBuy',
      '/login/customerLogin',
      '/order/confirmBuy',
      '/order/customerOrder',
    ]
    if ((pathList.indexOf(this.$route.path) != -1) || this.$route.query.source == "Friend-KY") {
      this.navShow = false;
      
    } else {
      this.navShow = true;
    }

    if(this.$route.path == "/curriculum/course"){
      this.isH5 = true
    } else {
      this.isH5 = false
    }

    // 判断还原globalData的数据
    if (localStorage.getItem("userInfo")) {
      this.$store.state.userInfo = JSON.parse(localStorage.getItem("userInfo"));
      // console.log(JSON.parse(localStorage.getItem("userInfo")));
      logininformation(JSON.parse(localStorage.getItem("userInfo")));
      this.isLogin = this.$store.state.userInfo.isLogin;
    }
    // vuex数据持久化
    if (localStorage.getItem("confirmOrder")) {
      this.$store.state.confirmOrder = JSON.parse(
        localStorage.getItem("confirmOrder")
      );
      localStorage.removeItem("confirmOrder");
    }
    window.addEventListener("beforeunload", () => {
      localStorage.setItem(
        "confirmOrder",
        JSON.stringify(this.$store.state.confirmOrder)
      );
    });
    // 提示语只显示最近一个
    this.$message.config({
      top: "calc(50% - 20px)",
      maxCount: 1,
    });
  },
  // 生命周期-实例挂载后调用
  mounted() {
    if(this.$store.state.userInfo.userId){
      // 刷新获取用户最新信息
      this.getUserInfo();
    }
    this.saveUserTrack(); // 记录用户轨迹
    this.FunminHeight();
    const _this = this;
    window.onresize = function () {
      _this.FunminHeight();
    };

    this.$nextTick(() => {
      // 1.禁用右键菜单
      document.oncontextmenu = new Function("event.returnValue=false");
      // 2.禁用鼠标选中
      document.onselectstart = new Function("event.returnValue=false");
      // 3.禁用页面打开控制台
      document.onkeydown = () => {
        //禁用F12
        if (window.event && window.event.keyCode == 123) {
          return false;
        //禁用ctrl+shift+i,
        } else if (window.event.ctrlKey && window.event.shiftKey && window.event.keyCode == 73) {
          return false;
        //屏蔽Shift+F10
        } else if (window.event.shiftKey && window.event.keyCode == 121) {
          return false;
        //禁用ctrl+shift+c,
        } else if (window.event.ctrlKey && window.event.shiftKey && window.event.keyCode == 67) {
          return false;
        }
        
      };
    });
  },
  watch: {
    $route() {
      this.isLogin = this.$store.state.userInfo.isLogin;
      this.listenRoute(this.$route.path);
    },
  },
};
</script>

<style lang="less">
.error-public {
  position: fixed;
  top: 60px;
  left: calc(50vw - 340px);
  padding: 0 10px;
  // width: 100%;
  height: 36px;
  line-height: 36px;
  color: #000000;
  background: #fef9e5;
  text-align: center;
  z-index: 999;
  display: flex;
  align-items: center;
  img {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
  p {
    font-size: 16px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #333333;
    line-height: 19px;
    span {
      cursor: pointer;
      color: @theme;
    }
  }
  .icon {
    cursor: pointer;
    margin-left: 20px;
    font-size: 12px;
    // height: 25px;
  }
}

.browserDataTable {
  padding: 0px 6px 6px;
  .table {
    .titleText,
    .tableContent {
      border: 1px solid #e8e8e8;
      display: flex;
      line-height: 54px;
      .name {
        width: 123px;
        border-right: 1px solid #e8e8e8;
      }
      .edition {
        width: 257px;
        border-right: 1px solid #e8e8e8;
      }
      .url {
        flex: 1;
      }
    }
    .titleText {
      background: #fafafa;
    }
    .tableContent {
      border-top: 0;
    }
  }
}

#app {
  min-width: 1400px;
  min-height: 100%;
  background: #f6f6fc;
}
.ant-breadcrumb-link {
  a {
    font-size: 16px;
    font-family: PingFangMedium;
  }
}

// 小屏分辨率内容区呈现1200px布局
@media screen and (max-width: 769px) {
  .h5App {
    min-width: auto !important;
  }
  .h5Nav {
    display: none;
  }
  .h5Foo {
    display: none;
  }
}
</style>
