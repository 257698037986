<template>
  <!-- 找回密码 -->
  <div class="find" :class="state==1 ? 'find_box': 'find_popup'">
    <div class="box">
      <div class="line-top"></div>
      <!-- 标题 -->
      <div class="box_title">
        <div class="title_main">{{ $route.query.set?'设置密码':'找回密码' }}</div>
        <div class="title_info">{{ $route.query.set?'Set pin?':'Forget pin?' }}</div>
      </div>
      <!-- 验证 -->
      <div class="box_input" v-if="step==1">
        <div class="input_item">
          <a-select
            ref="select"
            v-model="popCodeTxt"
            @change="changePopCode"
          >
            <a-select-option 
              v-for="(item, index) in popCode" 
              :key="index"
              :value="item.id">{{item.code}}</a-select-option>
          </a-select>
          <a-input v-model="phone" :disabled="$route.query.set" :bordered="false" placeholder="请输入手机号" />
        </div>
        <div class="input_item item_ver">
          <a-input v-model="verCodeImg" :bordered="false" placeholder="请输入图形验证码" />
          <div class="item_info1" @click="funCode()">{{code}}</div>
        </div>
        <div class="input_item">
          <a-input v-model="verCode" :maxLength="6" :bordered="false" placeholder="请输入短信验证码" />
          <div class="item_info2" v-if="isDxCode" @click="onDxCode()">获取验证码</div>
          <div class="item_info3" v-else>{{countdown}}秒后重新发送</div>
        </div>
      </div>
      <!-- 设置密码 -->
      <div class="box_input" v-if="step==2">
        <div class="input_item">
          <!-- :type="isVisible?'text':'password'"  -->
          <a-input 
            :class="{'item_password': !isVisible}"
            v-model="password" 
            :bordered="false" 
            placeholder="请输入新密码" />
          <div class="item_img" @click="changeVisible()">
            <img src="@/assets/image/login/pass_show.png" v-if="isVisible" alt="隐藏密码" title="隐藏密码">
            <img src="@/assets/image/login/pass_hide.png" v-else alt="密码可见" title="密码可见">
          </div>
        </div>
        <div class="input_item">
          <a-input 
            :class="{'item_password': !isVisible}"
            v-model="ConfirmPassword" 
            :bordered="false" 
            placeholder="请再次输入新密码" />
        </div>
        
      </div>
      <!-- 下一步 -->
      <div class="box_button">
        <a-button type="primary" v-if="step==1" @click="onNext()">下一步 </a-button>
        <a-button type="primary" v-if="step==2" @click="onEditPassword()">确认</a-button>
      </div>
      <!-- 提示语 -->
      <div class="box_hint" v-if="showHint">
        <div class="hint_main">
          <a-icon type="exclamation-circle" width="18" height="18" />
          <span class="main_txt">设置密码不一致，请重新输入</span>
        </div>
      </div>
      <!-- 关闭按钮 -->
      <div class="box_close" v-if="state==2">
        <img src="@/assets/image/login/close.png" alt="关闭" title="关闭">
        <div class="close_button" @click="closeFindPop()"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {encryptDes} from '@/unit/DESCypher.js' // 引用具体的方法
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    // 1页面 2弹框
    state: {
      type: Number,
      default: 1,
    }
  },
  // 数据对象
  data () {
    return {
      step: 1, //步骤
      countdown: 60,
      isDxCode: true, //是否获取验证码
      showHint: false, //提示语
      isVisible: false, //密码是否可见

      // 区号
      popCodeTxt: '+86',
      popCodeIndex: 0,
      popCode: [
        {
          id: '4',
          country: "中国",
          code: "+86"
        },
        {
          id: '1',
          country: "中国香港",
          code: "+852"
        },
        {
          id: '2',
          country: "中国澳门",
          code: "+853"
        },
        {
          id: '3',
          country: "中国台湾",
          code: "+886"
        }
      ],

      phone: '', //手机号
      code: '', //图形验证码
      verCodeImg: '', //图形验证码
      verCode: '', //验证码
      password: '', //密码
      ConfirmPassword: '', //确认密码
      mobileCheckStr: '', // 校验验证码返回字符取代修改密码时手机号
    }
  },
  // 事件处理器
  methods: {
    // 选择区号
    changePopCode(e, data) {
      this.popCodeTxt = e
      this.popCodeIndex = data.data.key
    },
    // 获取验证码
    onDxCode() {
      if (this.isDxCode) {
        let verCodeImg = JSON.parse(JSON.stringify(this.verCodeImg)).toLowerCase()
        let code = JSON.parse(JSON.stringify(this.code)).toLowerCase()
        if (!this.phone) {
          this.$message.warning('请输入手机号');
          return false;
        } else if (!this.$regular.phone.reg.test(this.phone)) {
          this.$message.warning('请输入正确的手机号');
          return false;
        } else if (!this.verCodeImg) {
          this.$message.warning('请输入图形验证码');
          return false;
        } else if (verCodeImg !== code) {
          this.$message.warning('请输入正确的图形验证码');
          return false;
        }

        this.$ajax({
          url: '/hxclass-pc/user/upPsSend',
          method: "get",
          params: {
            phone: this.phone, //手机号
            mobileType: this.popCode[this.popCodeIndex].id, //区号
          }
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$message.success("短信已发送，请注意查收！");
            // 倒计时
            this.sendemail(this.countdown);
          } else {
            this.$message.error(res.message);
          }
        })
      }
    },
    // 下一步
    onNext() {
      let verCodeImg = JSON.parse(JSON.stringify(this.verCodeImg)).toLowerCase()
      let code = JSON.parse(JSON.stringify(this.code)).toLowerCase()
      // 校验
      if (!this.phone) {
        this.$message.warning('请输入手机号');
        return false;
      } else if (!this.$regular.phone.reg.test(this.phone)) {
        this.$message.warning('请输入正确的手机号');
        return false;
      } else if (!this.verCodeImg) {
        this.$message.warning('请输入图形验证码');
        return false;
      } else if (verCodeImg !== code) {
        this.$message.warning('图形验证码输入错误');
        return false;
      } else if (!this.verCode) {
        this.$message.warning('请输入短信验证码');
        return false;
      } else if (!this.$regular.dxcode.reg.test(this.verCode)) {
        this.$message.warning(this.$regular.dxcode.msg);
        return false;
      }

      // 校验验证码
      this.$ajax({
        url: '/hxclass-pc/user/upPs/check',
        method: "get",
        params: {
          mobileType: this.popCode[this.popCodeIndex].id,
          phone: this.phone,
          captcha: this.verCode,
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.mobileCheckStr = res.data;
          this.step = 2
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 修改密码
    onEditPassword() {
      this.showHint = false
      if (!this.password) {
        this.$message.warning("请输入密码");
        return 
      } else if (!this.$regular.password.reg.test(this.password)) {
        this.$message.warning(this.$regular.password.msg);
        return 
      } else if (!this.ConfirmPassword) {
        this.$message.warning("请输入确认密码");
        return 
      } else if (this.password !== this.ConfirmPassword) {
        this.showHint = true
        this.$message.warning("两次密码输入不一致");
        return 
      }

      const newPassword = encryptDes(this.password)  //新密码
      const confirm = encryptDes(this.ConfirmPassword) // 确认新密码
      const encmobile = encryptDes(this.phone) // 手机号加密
      let password = encodeURIComponent(newPassword)
      let ConfirmPassword = encodeURIComponent(confirm)
      // let mobile = encodeURIComponent(encmobile)
      if(!this.mobileCheckStr) {
        return this.$message.warning("数据丢失，请重新校验手机号！");
      }
      this.$ajax({
        url: '/hxclass-pc/user/new/updatePs',
        method: "POST",
        params: {
          // phone: mobile,
          phone: this.mobileCheckStr,
          newPassword: password,
          confirm: ConfirmPassword,
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.$message.success('修改成功');
          // 跳到登录
          this.$router.replace('/login/loginIndex')
        } else {
          this.$message.error(res.message);
        }
      })
    },
    // 显示/隐藏密码
    changeVisible() {
      this.isVisible = !this.isVisible
    },
    // 关闭弹框
    closeFindPop() {
      this.$emit('closeFindPop', false)
    },
    // 倒计时
    sendemail(s) {
      if (s == 0) {
        this.countdown = 60;
        this.isDxCode = true;
        s = 60;
        clearTimeout(this.timeOut);
        return;
      } else {
        this.countdown = s;
        this.isDxCode = false;
        s--;
      }
      this.timeOut = setTimeout(() => {
        this.sendemail(s);
      }, 1000);
    },
    // 生成一段验证码
    funCode(){
      const e = 4 // 如果想显示6位数，5改成6即可
      const sCode = "a,b,c,d,e,f,g,h,i,j,k,m,n,p,q,r,s,t,u,v,w,x,y,z,A,B,C,E,F,G,H,J,K,L,M,N,P,Q,R,S,T,W,X,Y,Z,1,2,3,4,5,6,7,8,9,0";
      const aCode = sCode.split(",");
      const aLength = aCode.length;
      let text = ''
      for (let i = 0; i < e; i++) {
          const j = Math.floor(Math.random() * aLength);//获取到随机的索引值
          const txt = aCode[j];
          text += txt
      }
      // this.$set(this, 'thisCode', text)
      this.code = text // 生成的值
      // this.verCodeImg = text // 生成的值
    },
  },
  // 生命周期-实例创建完成后调用
  created () {
    if(this.$route.query.set) {
      this.phone = this.$store.state.userInfo.mobile;
    }
    // 生成验证码
    this.funCode()
  },
  // 生命周期-实例挂载后调用
  mounted () { },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
  @import "./login.less";
  .find {
    .box {
      box-sizing: content-box;
      position: relative;
      background: #FFFFFF;
      border-radius: 8px;
      width: 456px;
      margin: auto;
      // padding: 70px 40px 132px 40px;
      .line-top{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 24px;
        border-radius: 8px;
        background: linear-gradient(180deg, rgba(211,247,255,0.56) 0%, rgba(255,255,255,0) 100%);
      }
      // 标题
      .box_title {
        text-align: left;
        margin-bottom: 15px;
        .title_main {
          font-size: 36px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #000000;
          line-height: 54px;
          letter-spacing: 3px;
        }
        .title_info {
          font-size: 14px;
          font-family: Space Grotesk-Regular, Space Grotesk;
          font-weight: 400;
          color: #999999;
          line-height: 21px;
          letter-spacing: 1px;
        }
      }
      // 按钮
      .box_button {
        margin-top: 36px;
        .ant-btn {
          height: 50px;
          line-height: 50px;
        }
      }
      // 提示语
      .box_hint {
        // position: absolute;
        // bottom: 100;
        width: 456px;
        text-align: center;
        margin-top: 60px;
        .hint_main {
          display: inline-flex;
          font-size: 18px;
          font-family: PingFangMedium;
          font-weight: 400;
          color: #FFFFFF;
          padding: 12px;
          background: #3B90A7;
          box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.12);
          border-radius: 5px;
          .main_txt {
            margin-left: 8px;
          }
        }
      }
    }
  }
  // 页面
  .find_box { 
    .box {
      padding: 24px 70px;
      box-shadow: 4px 4px 40px 0px rgba(0,0,0,0.1);
    }
  }
  // 弹框
  .find_popup {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .35);
    z-index: 10;
    .box {
      box-sizing: border-box;
      width: auto;
      min-height: 640px;
      padding: 70px 40px;
      .box_hint {
        margin-top: 18px;
      }
    }
  }
  // 小屏分辨率内容区呈现1200px布局
  @media screen and (max-width: 16000px) {
    .find_popup {
      .box {
        min-height: auto;
      }
    }
    .find {
      .box {
        .box_title {
          .title_main {
            font-size: 28px;
          }
          .title_info {
            font-size: 12px;
          }
        }
        .box_hint {
          .hint_main {
            font-size: 16px;
          }
        }
      }
    }
  }
</style>
