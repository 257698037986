export default{
  // 公共的状态
  state: {
    list:null, // 确认订单商品列表
    type:null, // 订单类型：1.商品订单 2.电子照订单 3.补考订单 4.延期订单 5.会员订单
    address:null, // 地址id
    InvoiceId:null, // 发票id
    shortInvoiceId:null, // 暂存发票id
    couponList:null, // 优惠券列表
  },
  /*
    同步事件，
    将mutaions理解为store中的methods
    第一个参数是state, 第二参数是payload, 也就是自定义的参数.
  */
  mutations: {
    // 更新数据
    updatelist(state, props){
      state['list'] = props;
    },

    updateType(state, props){
      state['type'] = props;
    },

    updateAddress(state, props){
      state['address'] = props;
    },

    updateInvoiceId(state, props){
      state['InvoiceId'] = props;
    },

    updateShortInvoiceId(state, props){
      state['shortInvoiceId'] = props;
    },

    updateCouponList(state, props){
      state['couponList'] = props;
    },

    // 清除数据，包括
    clearorderMessage(state){
      state.list = null
      state.type = null
      state.address = null
      state.InvoiceId = null
      state.couponList = null
    },

  },
  /*
    异步事件
    actions 类似于 mutations
    actions提交的是mutations而不是直接变更状态
  */
  actions: {
    // 异步更新数据
    // actionsUpdateUserInfo (context, props) {
    //   context.commit('updateUserInfo', props)
    // }
  },
  // 嵌套模块
  modules: {}
}