export default[
  // 课程详情
  {
    path: 'course',
    name: 'course',
    component: () => import('@/views/curriculum/course.vue')
  },
  // 随机播放课程详情
  {
    path: 'courseRandom',
    name: 'courseRandom',
    component: () => import('@/views/curriculum/courseRandom.vue')
  },
  // 课程详情
  {
    path: 'coursePublic',
    name: 'coursePublic',
    component: () => import('@/views/curriculum/coursePublic.vue')
  }
]