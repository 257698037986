<template>
  <!-- 绑定手机号 -->
  <div class="bind" :class="state == 1 ? 'bind_box' : 'bind_popup'">
    <div class="box">
      <!-- 标题 -->
      <div class="box_title">
        <div class="title_main">绑定手机号</div>
        <div class="title_info">Sign up by Mobile No.</div>
      </div>
      <!-- 输入框 -->
      <div class="box_input">
        <div class="input_item">
          <a-select ref="select" v-model="popCodeTxt" @change="changePopCode">
            <a-select-option
              v-for="(item, index) in popCode"
              :key="index"
              :value="item.id"
              >{{ item.code }}</a-select-option
            >
          </a-select>
          <a-input
            v-model="phone"
            :bordered="false"
            placeholder="请输入手机号"
          />
        </div>
        <div class="input_item item_ver">
          <a-input
            v-model="verCodeImg"
            :bordered="false"
            placeholder="请输入图形验证码"
          />
          <div class="item_info1" @click="funCode()">{{ code }}</div>
        </div>
        <div class="input_item">
          <a-input
            :maxLength=6
            v-model="verCode"
            :bordered="false"
            placeholder="请输入短信验证码"
          />
          <div class="item_info2" v-if="isDxCode" @click="onDxCode()">
            获取验证码
          </div>
          <div class="item_info3" v-else>{{ countdown }}秒后重新发送</div>
        </div>
      </div>
      <!-- 协议条款 -->
      <div class="box_check">
        <a-checkbox v-model="checked">
          <div class="check_box">
            我已阅读并同意<span
              class="check_info"
              @click="$router.push('/privacyPolicy?sel=1')"
              >《华夏云课堂服务协议》</span
            >和<span
              class="check_info"
              @click="$router.push('/privacyPolicy?sel=2')"
              >《华夏云课堂隐私协议》</span
            >
          </div>
        </a-checkbox>
      </div>
      <!-- 下一步 -->
      <div class="box_button">
        <a-button type="primary" :loading="loading" @click="onBind()">确认</a-button>
      </div>
      <!-- 关闭按钮 -->
      <div class="box_close" v-if="state == 2">
        <img src="@/assets/image/login/close.png" alt="关闭" title="关闭" />
        <div class="close_button" @click="closeBindPop()"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    // 1页面 2弹框
    state: {
      type: Number,
      default: 1,
    },
    // 微信标识
    unionid: {
      type: String,
      default: '', //oztgU6o3vLsxqVj7zj-2l7Jc94BY
    }
  },
  // 数据对象
  data() {
    return {
      countdown: 60,
      isDxCode: true, //是否获取验证码
      checked: false, //协议条款

      // 区号
      popCodeTxt: "+86",
      popCodeIndex: 0,
      popCode: [
        {
          id: "4",
          country: "中国",
          code: "+86",
        },
        {
          id: "1",
          country: "中国香港",
          code: "+852",
        },
        {
          id: "2",
          country: "中国澳门",
          code: "+853",
        },
        {
          id: "3",
          country: "中国台湾",
          code: "+886",
        },
      ],

      phone: "", //手机号
      verCodeImg: "", //图形验证码
      verCode: "", //验证码

      obj: {
        isLogin: false,
      },
      loading: false,
    };
  },
  // 事件处理器
  methods: {
    // 选择区号
    changePopCode(e, data) {
      this.popCodeTxt = e;
      this.popCodeIndex = data.data.key;
    },
    // 获取验证码
    onDxCode() {
      let verCodeImg = JSON.parse(JSON.stringify(this.verCodeImg)).toLowerCase()
      let code = JSON.parse(JSON.stringify(this.code)).toLowerCase()
      // 校验
      if (!this.phone) {
        this.$message.warning("请输入手机号");
        return false;
      } else if (!this.$regular.phone.reg.test(this.phone)) {
        this.$message.warning("请输入正确的手机号");
        return false;
      } else if (!this.verCodeImg) {
        this.$message.warning("请输入图形验证码");
        return false;
      } else if (verCodeImg !== code) {
        this.$message.warning("图形验证码输入错误");
        return false;
      }

      this.$ajax({
        url: "/hxclass-pc/user/sms/bindwx",
        method: "get",
        params:{
          phone: this.phone, //手机号
          mobileType: this.popCode[this.popCodeIndex].id, //区号
        }
      }).then((res)=>{
        if(res.code == 200 && res.success){
          this.$message.success("短信已发送，请注意查收！");
          // 倒计时
          this.sendemail(this.countdown);
        }else{
          this.$message.error(res.message)
        }
      })
    },
    // 绑定手机号
    onBind() {
      let verCodeImg = JSON.parse(JSON.stringify(this.verCodeImg)).toLowerCase()
      let code = JSON.parse(JSON.stringify(this.code)).toLowerCase()
      // 校验
      if (!this.phone) {
        this.$message.warning("请输入手机号");
        return false;
      } else if (!this.$regular.phone.reg.test(this.phone)) {
        this.$message.warning("请输入正确的手机号");
        return false;
      } else if (!this.verCodeImg) {
        this.$message.warning("请输入图形验证码");
        return false;
      } else if (verCodeImg !== code) {
        this.$message.warning("图形验证码输入错误");
        return false;
      } else if (!this.verCode) {
        this.$message.warning("请输入短信验证码");
        return false;
      } else if (!this.$regular.dxcode.reg.test(this.verCode)) {
        this.$message.warning(this.$regular.dxcode.msg);
        return false;
      } else if (!this.checked) {
        let _that = this
        _that.$confirm({
          title: '提示',
          icon: 'bell',
          content: '需同意并阅读《华夏云课堂服务协议》和《华夏云课堂隐私协议》！',
          okText: '同意',
          onOk() {
            _that.checked = true
            _that.onBind()
          },
          onCancel() {},
        });
        return false;
      }

      let mobileType = this.popCode[this.popCodeIndex].id
      this.loading = true
      this.$ajax({
        url: "/hxclass-pc/login/wechat/bindmobile?mobile=" + this.phone + '&mobileType=' + mobileType + '&unionid=' + this.unionid + '&verCode=' + this.verCode ,
        method: "post",
      }).then((res)=>{
        if(res.code == 200 && res.success){
          // 绑定成功
          this.$message.success("绑定成功！");
          // 获取用户信息
          this.getUserInfo()
        }else{
          this.$message.error(res.message)
        }
        this.loading = true
      })
    },
    // 获取用户信息
    getUserInfo() {
      this.$ajax({
        url: "/hxclass-pc/user/info",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.obj = res.data;
          this.obj.isLogin = true;
          this.$store.commit("updateUserInfo", this.obj);
          // 页面
          if (this.state == 1) {
            // this.$router.push("/personalCenter/DevLayout");
            this.$router.push("/");
          }
          // 弹框
          else if (this.state == 2) {
            // 关闭绑定手机号弹框
            this.$emit("closeBindPop", false);
          }

          // let tolink = this.$store.state.userInfo.tolink;
          // this.checkIdent();
          // if (tolink == '/login/login' || tolink.indexOf('/login/clause') > -1 || tolink == '/login/retrievePassword') {
          //   this.$router.push('/home');
          //   return
          // }
          // this.$router.push(this.$store.state.userInfo.tolink);
        }
      });
    },
    // 关闭弹框
    closeBindPop() {
      this.$emit("closeBindPop", false);
    },
    // 倒计时
    sendemail(s) {
      if (s == 0) {
        this.countdown = 60;
        this.isDxCode = true;
        s = 60;
        clearTimeout(this.timeOut);
        return;
      } else {
        this.countdown = s;
        this.isDxCode = false;
        s--;
      }
      this.timeOut = setTimeout(() => {
        this.sendemail(s);
      }, 1000);
    },
    // 生成一段验证码
    funCode() {
      const e = 4; // 如果想显示6位数，5改成6即可
      const sCode =
        "a,b,c,d,e,f,g,h,i,j,k,m,n,p,q,r,s,t,u,v,w,x,y,z,A,B,C,E,F,G,H,J,K,L,M,N,P,Q,R,S,T,W,X,Y,Z,1,2,3,4,5,6,7,8,9,0";
      const aCode = sCode.split(",");
      const aLength = aCode.length;
      let text = "";
      for (let i = 0; i < e; i++) {
        const j = Math.floor(Math.random() * aLength); //获取到随机的索引值
        const txt = aCode[j];
        text += txt;
      }
      // this.$set(this, 'thisCode', text)
      this.code = text; // 生成的值
      // this.verCodeImg = text; // 生成的值
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 生成验证码
    this.funCode();
  },
  // 生命周期-实例挂载后调用
  mounted() {},
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
@import "./login.less";
.bind {
  .box {
    box-sizing: content-box;
    position: relative;
    background: #ffffff;
    border-radius: 8px;
    width: 456px;
    margin: auto;
    padding: 70px 40px 68px 40px;
    // 标题
    .box_title {
      .title_main {
        font-size: 36px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #000000;
        line-height: 54px;
        letter-spacing: 3px;
      }
      .title_info {
        font-size: 14px;
        font-family: Space Grotesk-Regular, Space Grotesk;
        font-weight: 400;
        color: #999999;
        line-height: 21px;
        letter-spacing: 1px;
      }
    }
    // 按钮
    .box_button {
      margin-top: 36px;
      .ant-btn {
        height: 50px;
        line-height: 50px;
      }
    }
    // 提示语
    .box_hint {
      // position: absolute;
      // bottom: 100;
      width: 456px;
      text-align: center;
      margin-top: 18px;
      .hint_main {
        display: inline-block;
        font-size: 18px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #ffffff;
        padding: 12px;
        background: #3b90a7;
        box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.12);
        border-radius: 5px;
      }
    }
  }
}
// 弹框
.bind_popup {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 10;
}
// 页面
.bind_box {
  .box {
    padding: 43px 0 55px 0;
  }
}
// 小屏分辨率内容区呈现1200px布局
@media screen and (max-width: 16000px) {
  .bind {
    .box {
      .box_title {
        .title_main {
          font-size: 28px;
        }
        .title_info {
          font-size: 12px;
        }
      }
    }
  }
}
</style>
