export default [
  {
    path: '/',
    redirect: 'Query'
  },
  // {
  //   path: 'Query',
  //   component: () => import('@/views/Query/index.vue')
  // },
  {
    path: 'Query',
    component: () => import('@/views/Query/newIndex.vue')
  },
]