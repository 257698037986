<template>
  <!-- 登录注册 -->
  <div class="login_wrap" :class="{ popLogin: state == 2 }">
    <div class="login_box" :class="{'login_box_bg': state == 2}">
      <div class="line-top"></div>
      
      <div class="box">
        <!-- 标题 -->
        <div class="box_title" v-if="state == 1">
          <div v-if="type == 1">
            <div class="title_main">欢迎注册华夏云课堂</div>
            <div class="title_info">
              Welcome to Register
            </div>
          </div>
          <div v-if="type == 2">
            <div class="title_main">欢迎登录华夏云课堂</div>
            <div class="title_info">Log In</div>
          </div>
        </div>
        <!-- 登录方式 -->
        <!-- :class="state == 1 ? 'type_left' : 'type_center'" -->
        <div
          class="box_type type_center"
          v-if="type == 2"
        >
          <a-tabs v-model="loginType" centered>
            <a-tab-pane key="2" tab="短信登录"></a-tab-pane>
            <a-tab-pane key="1" tab="密码登录"></a-tab-pane>
            <a-tab-pane key="3" tab="微信登录"></a-tab-pane>
          </a-tabs>
        </div>
        <div v-if="type == 1 || (type == 2 && loginType !== '3')">
          <!-- 输入框 -->
          <div class="box_input">
            <div class="input_item">
              <a-select
                v-if="loginType == 2"
                ref="select"
                v-model="popCodeTxt"
                @change="changePopCode"
              >
                <a-select-option
                  v-for="(item, index) in popCode"
                  :key="index"
                  :value="item.id"
                  >{{ item.code }}</a-select-option
                >
              </a-select>
              <a-input
                v-model="phone"
                :bordered="false"
                :placeholder="type==1||loginType=='2'?'请输入手机号':'请输入手机号'"
              />
            </div>
            <div
              class="input_item item_ver"
              v-if="type == 1 || (type == 2 && loginType == '2')"
            >
              <a-input
                v-model="verCodeImg"
                :bordered="false"
                placeholder="请输入图形验证码"
              />
              <div class="item_info1" @click="funCode()">{{ code }}</div>
            </div>
            <div
              class="input_item"
              v-if="type == 1 || (type == 2 && loginType == '2')"
            >
              <a-input
                :maxLength=6
                v-model="verCode"
                :bordered="false"
                placeholder="请输入短信验证码"
              />
              <div class="item_info2" v-if="isDxCode" @click="onDxCode()">
                获取验证码
              </div>
              <div class="item_info3" v-else>{{ countdown }}秒后重新发送</div>
            </div>
            <div
              class="input_item"
              v-if="type == 1 || (type == 2 && loginType == '1')"
            >
              <!-- :type="isVisible ? 'text' : 'password'" -->
              <a-input
                :class="{'item_password': !isVisible}"
                v-model="password"
                :bordered="false"
                :placeholder="type==1 ? '请设置登录密码' : '请输入登录密码'"
              />
              <div class="item_img" @click="changeVisible()">
                <img
                  src="@/assets/image/login/pass_show.png"
                  v-if="isVisible"
                  alt="隐藏密码"
                  title="隐藏密码"
                />
                <img
                  src="@/assets/image/login/pass_hide.png"
                  v-else
                  alt="密码可见"
                  title="密码可见"
                />
              </div>
            </div>
          </div>
          <!-- 协议条款 -->
          <div class="box_check">
            <a-checkbox v-model="checked">
              <!-- 我已阅读并同意 -->
              <div class="check_box">
                我已阅读并同意<span
                  class="check_info"
                  @click="$router.push('/privacyPolicy?sel=1')"
                  >《华夏云课堂服务协议》</span
                >和<span
                  class="check_info"
                  @click="$router.push('/privacyPolicy?sel=2')"
                  >《华夏云课堂隐私协议》</span
                >
              </div>
            </a-checkbox>
          </div>
          <!-- 登录注册按钮 -->
          <div class="box_button">
            <a-button type="primary" v-if="type == 1" :loading="loading" @click="onSignIn()"
              >立即注册</a-button
            >
            <a-button type="primary" v-if="type == 2" :loading="loading" @click="onLogin()"
              >立即登录</a-button
            >
          </div>
          <!-- 去登录 -->
          <div class="box_register" v-if="type == 1">
            已有账号，<span class="register_info" @click="changeType(2)"
              >立即登录</span
            >
          </div>
          <!-- 忘记密码、去注册 -->
          <div class="box_login1" v-if="type == 2 && loginType == '1'">
            <div class="login_left" @click="toFindPassword()">忘记密码？</div>
            <div class="login_right">
              没有账号，<span class="register_info" @click="changeType(1)"
                >立即注册</span
              >
            </div>
          </div>
          <!-- 短信验证提示语 -->
          <div class="box_login2" v-if="type == 2 && loginType == '2'">
            未注册的手机号码验证后自动创建云课堂账号
          </div>
        </div>
        <!-- 微信登录 -->
        <div class="box_wx" v-if="type == 2 && loginType == '3'">
          <div class="wx_img">
            <!-- <img src="@/assets/image/login/wxLogin.png" alt="微信登录" title="微信登录"> -->
            <iframe
              id="myFrame"
              sandbox="allow-scripts allow-top-navigation allow-same-origin"
              :src="wxCode"
              frameborder="0"
              width="400px"
              height="400px"
            ></iframe>
          </div>
          <!-- <div class="wx_info">
            <span class="info_icon">—</span>
            <span class="info_main">请使用手机微信扫一扫</span>
            <span class="info_icon">—</span>
          </div> -->
        </div>
        <!-- 关闭按钮 -->
        <div class="box_close" v-if="state == 2">
          <img src="@/assets/image/login/close.png" alt="关闭" title="关闭" />
          <div class="close_button" @click="closeLoginPop()"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { logininformation } from "@/unit/common.js";
import {encryptDes} from '@/unit/DESCypher.js' // 引用具体的方法
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    // 1页面 2弹框
    state: {
      type: Number,
      default: 2,
    },
    // 1注册 2登录
    type: {
      type: Number,
      default: 2,
    },
  },
  // 数据对象
  data() {
    return {
      // type: 2, //1注册 2登录
      loginType: "2", //登录类型 1密码 2短信 3微信

      loading: false,
      checked: false, //协议条款
      isVisible: false, //密码是否可见

      phone: "", //手机号
      password: "", //密码
      verCodeImg: "", // 图形验证码
      verCode: "", // 短信验证码

      code: "", // 图形验证码
      countdown: 60, //验证码倒计时
      isDxCode: true, //是否获取验证码

      // 区号
      popCodeTxt: "+86",
      popCodeIndex: 0,
      popCode: [
        {
          id: "4",
          country: "中国",
          code: "+86",
        },
        {
          id: "1",
          country: "中国香港",
          code: "+852",
        },
        {
          id: "2",
          country: "中国澳门",
          code: "+853",
        },
        {
          id: "3",
          country: "中国台湾",
          code: "+886",
        },
      ],

      // 微信二维码
      wxCode: "",
      unionid: '', //oztgU6o3vLsxqVj7zj-2l7Jc94BY
      openId: '', //o0r_l6LUF61JC6bpQJxKfwvS1L7M
      accessToken: '', //57_x_Ir0Evl0muM5eo46mfTG-Kb6-VPBDSQpFIYDlefsO3syl4b2bXeGd6-qoCP5SXdhprhd3KCecMZ5zS1zcMRZjA7TCSudiVNMdE4NyiJyOM

      // 假数据
      obj: {
        userName: "测试账户张三",
        account: "root",
        password: "123456aa",
        userType: 1,
        photo: "https://www.longyc.cn/logo.jpg",
        phone: "17600380046",
        userName: "测试账户张三",
        sex: 1,
        age: 30,
        birthday: "1990-02-22",
        address: "北京市朝阳区颐和路怡和阳光大厦C座1304号",
        token: "111",
      },
    };
  },
  // 事件处理器
  methods: {
    // 选择区号
    changePopCode(e, data) {
      this.popCodeTxt = e;
      this.popCodeIndex = data.data.key;
    },
    // 获取验证码
    onDxCode() {
      if (this.isDxCode) {
        let verCodeImg = JSON.parse(JSON.stringify(this.verCodeImg)).toLowerCase()
        let code = JSON.parse(JSON.stringify(this.code)).toLowerCase()
        if (!this.phone) {
          this.$message.warning("请输入手机号");
          return false;
        } else if (!this.$regular.phone.reg.test(this.phone)) {
          this.$message.warning("请输入正确的手机号");
          return false;
        } else if (!this.verCodeImg) {
          this.$message.warning("请输入图形验证码");
          return false;
        } else if (verCodeImg !== code) {
          this.$message.warning("请输入正确的图形验证码");
          return false;
        }

        let url = "";
        if (this.type == 1) {
          // 注册
          url = "/hxclass-pc/user/sendSM";
        } else if (this.type == 2) {
          // 短信登录
          url = "/hxclass-pc/user/loginSendSMS";
        }

        this.$ajax({
          url: url,
          method: "get",
          params: {
            phone: this.phone, //手机号
            mobileType: this.popCode[this.popCodeIndex].id, //区号
          },
        }).then((res) => {
          if (res.code == 200 && res.success) {
            this.$message.success("短信已发送，请注意查收！");
            // 倒计时
            this.sendemail(this.countdown);
          } else {
            this.$message.error(res.message);
          }
        });
      }
    },
    // 获取用户信息
    getUserInfo() {
      this.$ajax({
        url: "/hxclass-pc/user/info",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.obj = res.data;
          logininformation(res.data);
          this.obj.isLogin = true;
          this.$store.commit("updateUserInfo", this.obj);
          let loginState = this.$store.state.userInfo.loginState || 1
          // 页面
          // if (loginState == 1) {
          //   this.$router.push("/");
          // }
          // 弹框
          if (loginState == 2) {
            // 关闭弹框
            this.$emit("changeLoginPop", 1);
            // 返回上一页
            // this.$router.go(-1)
          }

          let tolink = this.$store.state.userInfo.tolink;
          if (tolink) {
            this.$router.replace(this.$store.state.userInfo.tolink);
            return
          }
          this.$router.replace('/');
        }
      });
    },
    // 立即注册
    onSignIn() {
      let verCodeImg = JSON.parse(JSON.stringify(this.verCodeImg)).toLowerCase()
      let code = JSON.parse(JSON.stringify(this.code)).toLowerCase()
      // 校验
      if (!this.phone) {
        this.$message.warning("请输入手机号");
        return false;
      } else if (!this.$regular.phone.reg.test(this.phone)) {
        this.$message.warning("请输入正确的手机号");
        return false;
      } else if (!this.verCodeImg) {
        this.$message.warning("请输入图形验证码");
        return false;
      } else if (verCodeImg !== code) {
        this.$message.warning("图形验证码输入错误");
        return false;
      } else if (!this.verCode) {
        this.$message.warning("请输入短信验证码");
        return false;
      } else if (!this.$regular.dxcode.reg.test(this.verCode)) {
        this.$message.warning(this.$regular.dxcode.msg);
        return false;
      } else if (!this.password) {
        this.$message.warning("请输入密码");
        return false;
      } else if (!this.$regular.password.reg.test(this.password)) {
        this.$message.warning(this.$regular.password.msg);
        return false;
      } else if (!this.checked) {
        let _that = this
        _that.$confirm({
          title: '提示',
          icon: 'bell',
          content: '需同意并阅读《华夏云课堂服务协议》和《华夏云课堂隐私协议》！',
          okText: '同意',
          onOk() {
            _that.checked = true
            _that.onSignIn()
          },
          onCancel() {},
        });
        return false;
      }

      // 密码明文转密
      const encryptedData = encryptDes(this.password)
      let password = encodeURIComponent(encryptedData)

      let mobileType = this.popCode[this.popCodeIndex].id;
      this.loading = true // 增加防抖
      this.$ajax({
        url: "/hxclass-pc/user/register",
        method: "POST",
        params:{
          phone: this.phone,
          mobileType: mobileType,
          verCode: this.verCode,
          password: password,
        }

      }).then((res) => {
        if (res.code == 200 && res.success) {
          // 注册成功
          this.$message.success("注册成功！");
          // 获取用户信息
          this.getUserInfo();
        } else {
          this.$message.error(res.message);
          this.verCodeImg = '';
          // 生成验证码
          this.funCode();
        }
        this.loading = false
      });
    },
    // 立即登录
    onLogin() {
      // 校验手机号为空
      if (!this.phone) {
        this.$message.warning("请输入手机号");
        return false;
      }

      // 密码登录
      if (this.loginType == "1") {
        // 校验密码
        if (!this.password) {
          this.$message.warning("请输入密码");
          return false;
        } 
        // else if (!this.$regular.password.reg.test(this.password)) {
        //   this.$message.warning(this.$regular.password.msg);
        //   return false;
        // }
      }
      // 短信登录
      if (this.loginType == "2") {
        let verCodeImg = JSON.parse(JSON.stringify(this.verCodeImg)).toLowerCase()
        let code = JSON.parse(JSON.stringify(this.code)).toLowerCase()
        if (!this.$regular.phone.reg.test(this.phone)) {
          this.$message.warning("请输入正确的手机号");
          return false;
        }
        // 校验图形验证码
        else if (!this.verCodeImg) {
          this.$message.warning("请输入图形验证码");
          return false;
        } else if (verCodeImg != code) {
          this.$message.warning("图形验证码输入错误");
          return false;
        }
        // 校验短信验证码
        else if (!this.verCode) {
          this.$message.warning("请输入短信验证码");
          return false;
        } else if (!this.$regular.dxcode.reg.test(this.verCode)) {
          this.$message.warning(this.$regular.dxcode.msg);
          return false;
        }
      }
      // 校验协议条款
      if (!this.checked) {
        let _that = this
        _that.$confirm({
          title: '提示',
          icon: 'bell',
          content: '需同意并阅读《华夏云课堂服务协议》和《华夏云课堂隐私协议》！',
          okText: '同意',
          onOk() {
            _that.checked = true
            _that.onLogin()
          },
          onCancel() {},
        });
        return false;
      }

      let url = "";
      let mobileType = this.popCode[this.popCodeIndex].id;
      this.loading = true // 增加防抖
      // 密码登录
      if (this.loginType == "1") {
        // 密码明文转密
        const encryptedData = encryptDes(this.password)
        let password = encodeURIComponent(encryptedData)
        url =
          "/hxclass-pc/security/login?username=" +
          this.phone +
          "&password=" +
          password;
      }
      // 短信登录
      else if (this.loginType == "2") {
        url =
          "/hxclass-pc/security/sms/login?mobile=" +
          this.phone +
          "&mobileType=" +
          mobileType +
          "&smsCode=" +
          this.verCode;
      }

      // 登录
      this.$ajax({
        url: url,
        method: "POST",
      }).then((res) => {
        if (res.code !== 500) {
          // 登录成功
          this.$message.success("登录成功！");
          // 获取用户信息
          this.getUserInfo();
        } else {
          this.$message.error(res.message);
          this.verCodeImg = '';
          // 生成验证码
          this.funCode();
        }
        this.loading = false
      });
    },
    // 获取微信二维码
    getWxCode() {
      this.$ajax({
        url: "/hxclass-pc/login/wx",
        method: "get",
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.wxCode = res.data;

          // // 存储页面展现方式
          // this.$store.commit("updateUserInfo", {
          //   loginState: this.state, // 1页面 2弹框
          // });
        }
      });
    },
    // 获取微信token
    getWxToken() {
      // https://alpha.hxclass.cn/hxclass-pc/login/accesstoken?code=001fu3100YkZRN1qtR000peS790fu31k&state=null
      // 获取openid与token
      if(this.$route.query.code || this.$route.query.state){
        this.$ajax({
          url: "/hxclass-pc/login/accesstoken",
          method: "get",
          params:{
            code: this.$route.query.code,
            state: this.$route.query.state,
            // code: '01197SGa10YDaD0TjCGa1peEMI197SGU',
            // state: null,
          }
        }).then((res)=>{
          if(res.code == 200 && res.success){
            let data = JSON.parse(res.data)
            this.unionid = data.unionid
            console.log('你好',this.unionid );
            this.openId = data.openId
            this.accessToken = data.access_token
            // 微信回调
            this.onWxLogin()
          }else{
            this.$message.error(res.message)
          }
        })
      }
    },
    // 微信登录
    onWxLogin() {
      this.$ajax({
        url: "/hxclass-pc/login/callback",
        method: "get",
        params:{
          accessToken: this.accessToken,
          openId: this.openId
        }
      }).then((res)=>{
        // 登录成功
        if(res.code == 200 && res.success){
          // 获取用户信息
          this.getUserInfo();
        }else if (res.code == 900){
          // 绑定手机号
          let loginState = this.$store.state.userInfo.loginState
          // 页面
          if (loginState == 1) {
            // this.$router.push('/login/bindPhone')
            this.$router.replace({
              path:'/login/bindPhone', 
              query:{
                unionid: this.unionid
              }
            })
            // this.$router.push({
            //   name: 'bindPhone',
            //   params: {
            //     'unionid': this.unionid
            //   }
            // })
          } 
          // 弹框
          else if (loginState == 2) {
            // 关闭登录弹框，打开绑定手机号弹框
            // this.$emit("changeLoginPop", 3);
            let userInfo = this.$store.state.userInfo
            userInfo.showLoginPop = false
            userInfo.showBindPop = true
            userInfo.unionid = this.unionid
            this.$store.commit("updateUserInfo", userInfo);
            // 返回上一页
            this.$router.go(-1)
          }
        }
      })
    },
    // 显示/隐藏密码
    changeVisible() {
      this.isVisible = !this.isVisible;
    },
    // 忘记密码
    toFindPassword() {
      if (this.state == 1) {
        this.$router.push("/login/findPassword");
      } else if (this.state == 2) {
        this.$emit("changeLoginPop", 2);
      }
    },
    // 注册登录切换
    changeType(type) {
      this.$emit("changeType", type);
    },
    // 关闭弹框
    closeLoginPop() {
      this.$emit("changeLoginPop", 1);
    },
    // 倒计时
    sendemail(s) {
      if (s == 0) {
        this.countdown = 60;
        this.isDxCode = true;
        s = 60;
        clearTimeout(this.timeOut);
        return;
      } else {
        this.countdown = s;
        this.isDxCode = false;
        s--;
      }
      this.timeOut = setTimeout(() => {
        this.sendemail(s);
      }, 1000);
    },
    // 生成验证码
    funCode() {
      const e = 4; // 如果想显示6位数，5改成6即可
      const sCode =
        "a,b,c,d,e,f,g,h,i,j,k,m,n,p,q,r,s,t,u,v,w,x,y,z,A,B,C,E,F,G,H,J,K,L,M,N,P,Q,R,S,T,W,X,Y,Z,1,2,3,4,5,6,7,8,9,0";
      const aCode = sCode.split(",");
      const aLength = aCode.length;
      let text = "";
      for (let i = 0; i < e; i++) {
        const j = Math.floor(Math.random() * aLength); //获取到随机的索引值
        const txt = aCode[j];
        text += txt;
      }
      // this.$set(this, 'thisCode', text)
      this.code = text; // 生成的值
      // this.verCodeImg = text; // 生成的值
    },
  },
  // 生命周期-实例创建完成后调用
  created() {
    // 生成验证码
    this.funCode();
    // 获取微信二维码
    this.getWxCode();
    // this.$store.commit("updateUserInfo", {
    //   loginState: this.state, // 1页面 2弹框
    // });
    // // 获取微信token
    // this.getWxToken();
    // this.onWxLogin()
  },
  // 生命周期-实例挂载后调用
  mounted() {
    // 获取微信token
    this.getWxToken();
  },
  // 生命周期-实例销毁离开后调用
  destroyed() {},
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {},
};
</script>

<style lang="less" scoped>
@import "./login.less";
.popLogin {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.35);
  z-index: 10;
  /deep/.ant-tabs-bar {
    margin: 0;
  }
}
.login_box {
  // position: fixed;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  display: inline-block;
  position: relative;
  padding: 24px 70px;
  z-index: 10;
  border-radius: 8px;
  background: #ffffff;
}
.login_box_bg {
  background: #ffffff;
}
.impowerBox .qrcode {
  display: none;
}
.login_wrap {
  .line-top{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 24px;
    border-radius: 8px;
    background: linear-gradient(180deg, rgba(211,247,255,0.56) 0%, rgba(255,255,255,0) 100%);
  }
  .title{
    text-align: left;
    margin-bottom: 15px;
    .h1{
      font-size: 20px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 30px;
      letter-spacing: 1px;
    }
    .h2{
      font-size: 14px;
      font-family: Space Grotesk-Medium, Space Grotesk;
      font-weight: 500;
      color: rgba(51,51,51,0.7);
      line-height: 21px;
      letter-spacing: 1px;
    }
  }
  .box {
    width: 456px;
    // 标题
    .box_title {
      text-align: left;
      margin-bottom: 15px;
      .title_main {
        font-size: 32px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #333333;
        line-height: 48px;
        letter-spacing: 3px;
      }
      .title_info {
        font-size: 14px;
        font-family: Space Grotesk-Regular, Space Grotesk;
        font-weight: 400;
        color: #999999;
        line-height: 21px;
      }
    }
    // 登录状态
    .box_type {
      &.type_left {
        text-align: left;
      }
      &.type_center {
        text-align: left;
      }
      /deep/.ant-tabs {
        display: inline-block;
      }
      /deep/.ant-tabs-tab {
        color: #333333;
        font-size: 20px;
        font-family: PingFangMedium;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 2px;
        padding: 12px 0;
      }
      /deep/.ant-tabs-nav .ant-tabs-tab-active {
        color: #333333;
        font-size: 22px;
        font-weight: bold;
      }
      /deep/.ant-tabs-nav .ant-tabs-tab:hover {
        color: #333333;
      }
      /deep/.ant-tabs-ink-bar {
        height: 4px;
        width: 25px !important;
        background-color: @border-color;
      }
    }
    // 微信登录
    .box_wx {
      // padding-bottom: 79px;
      .wx_img {
        text-align: center;
        padding: 48px 0 23px 0;
        img {
          width: 220px;
        }
        .title {
          display: none;
        }
      }
      .wx_info {
        text-align: center;
        font-size: 20px;
        .info_icon {
          color: #ebebeb;
        }
        .info_main {
          font-family: PingFangMedium;
          font-weight: 400;
          color: #666666;
          margin: 0 10px;
        }
      }
    }
    // 切换登录注册
    .box_register {
      font-size: 18px;
      font-family: PingFangMedium;
      font-weight: 500;
      color: #666666;
      margin-top: 24px;
      .register_info {
        color: @border-color;
        cursor: pointer;
      }
    }
    // 去注册
    .box_login1 {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
      .login_left {
        font-size: 20px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #666666;
        cursor: pointer;
      }
      .login_right {
        font-size: 20px;
        font-family: PingFangMedium;
        font-weight: 400;
        color: #999999;
        .register_info {
          color: @border-color;
          cursor: pointer;
        }
      }
    }
    // 短信登录提示语
    .box_login2 {
      text-align: center;
      font-size: 18px;
      font-family: PingFangMedium;
      font-weight: 400;
      color: #999999;
      line-height: 27px;
      margin-top: 28px;
    }
  }
}
// 小屏分辨率内容区呈现1200px布局
@media screen and (max-width: 16000px) {
  .login_box {
    // padding-top: 18px;
  }
  .login_wrap {
    .box {
      .box_title {
        .title_main {
          font-size: 28px;
        }
        .title_info {
          font-size: 12px;
        }
      }
      .box_type {
        /deep/.ant-tabs-tab {
          font-size: 20px;
        }
        /deep/.ant-tabs-nav .ant-tabs-tab-active {
          font-size: 20px;
        }
      }
      .box_login1 {
        .login_left {
          font-size: 16px;
        }
        .login_right {
          font-size: 16px;
        }
      }
      .box_login2 {
        font-size: 16px;
        margin-top: 40px;
      }
      .box_register {
        font-size: 16px;
        // margin-top: 40px;
        margin-top: 30px;
      }
    }
  }
}
</style>
