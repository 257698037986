export default [
  // {
  //   path: '/',
  //   component: () => import('@/views/index/index.vue')
  // },
  // 首页改版
  {
    path: '/',
    component: () => import('@/views/index/revisionHome/index.vue')
  },
  // 百问百答详情
  {
    path: '/ArticleDetail',
    component: () => import('@/views/index/revisionHome/ArticleDetail.vue')
  },
  // 首页课程分类页面
  {
    path: 'courseListPage',
    component: () => import('@/views/index/revisionHome/columnCourse.vue')
  },
  {
    path: 'QaA',
    component: () => import('@/views/index/QaA.vue')
  },
  {
    path: 'active',
    component: () => import('@/views/index/active.vue')
  },
  {
    path: 'info',
    component: () => import('@/views/index/info.vue')
  },
  {
    path: 'infoDetail',
    component: () => import('@/views/index/infoDetail.vue')
  },
  {
    path: 'voiceDetail',
    component: () => import('@/views/index/voiceDetail.vue')
  },
  {
    path: 'ebookDetail',
    component: () => import('@/views/index/ebookDetail.vue')
  },
  // 隐私政策
  {
    path: 'privacyPolicy',
    component: () => import('@/views/index/privacyPolicy.vue')
  },
  // // 服务政策
  // {
  //   path: 'ServiceAgreement',
  //   component: () => import('@/views/index/ServiceAgreement.vue')
  // },
  // 第三方协议清单
  {
    path: 'detailedList',
    component: () => import('@/views/index/detailedList.vue')
  },
  // 关于我们
  {
    path: 'about',
    component: () => import('@/views/index/about.vue')
  },
  // 测试地址守卫登录页
  {
    path: 'pswLogin',
    component: () => import('@/views/index/pswLogin.vue')
  },
  // 科普公益
  {
    path: 'publicBenefit',
    component: () => import('@/views/index/publicBenefit.vue')
  },
  // 科普视频详情
  {
    path: 'videoDetail',
    component: () => import('@/views/index/videoDetail.vue')
  },
  // 知识解析列表
  {
    path: 'videoList',
    component: () => import('@/views/index/revisionHome/videoList.vue')
  },
]