let obj = new Object()

if(process.env.NODE_ENV == 'development'){
  // 开发环境
  obj = {
    // 代理前缀
    baseUrl: 'api',
    // 请求地址
    target: 'http://192.168.0.109:8083/',
    // 图片地址
    pictureUrl: 'https://static.cdpservice.com',
    // 支付宝返回地址
    payReturnUrl: 'http://192.168.110.161:80/',
  }
}else{ 
  // 生产环境
  obj = {
    // 代理前缀
    baseUrl: '',
    // 请求地址
    target: '',
    // 图片地址
    pictureUrl: 'https://static.cdpservice.com',
    // 支付宝返回地址
    payReturnUrl: 'https://hxclass.cn/',
  }
}

export default obj