<template>
  <div class="noMessage">
    <img :src="imgSrc" alt="">
    <p>{{msg}}</p>
  </div>
</template>

<script>
export default {
  props: {
    msg: {
      type: String,
      default: ''
    },
    imgSrc: {
      type: String,
      default: require('@/assets/image/noMessage.png')
    }
  },
  data(){
    return {

    }
  }
}
</script>

<style scoped lang="less">
  .noMessage{
    width: 100%;
    padding-bottom: 57px;
    text-align: center;
    img{
      width: 221px;
      height: 219px;
    }
    p{
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #2D3951;
      line-height: 28px;
      margin-top: 23px;
      text-align: center;
    }
  }
</style>